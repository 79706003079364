import React, { useEffect, useContext, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarNotificationsContext } from '@dtx/ui-core/src/context/SnackbarNotificationsProvider';
import { useUserSession } from "../../../hooks/auth/hooks";
import { AsyncStore } from "@dtx/ui-core/src/query";
import { MarketplaceType, Pad, PadPayload, SetupCriteria } from "./pad";
import PadSetup from "./padSetup";

interface PadFormProps {
  pad: Pad | null;
  customMarginError?: string | null;
  store: AsyncStore;
  editing: boolean
}

export const PadForm = (props: PadFormProps) => {
  const { pad } = props;
  const location = useLocation();
  const padToEdit = location.state?.pad || null;
  const isActive = location.state?.pad.active || true;
  const refreshKey = location.state?.refreshKey || null;
  const { showNotification } = useContext(SnackbarNotificationsContext);
  const [hideSubmitButton,setHideSubmitButton] = useState(false);

  const defaultPadValues: Pad = {
    "pad_id": null,
    "pad_name": '',
    "marketplace_type": MarketplaceType.PMP,
    "dsp": null,
    "setup_criteria": SetupCriteria.seatId,
    "discount": 0.5,
    "active": true,
    "created_at": new Date().toISOString(),
    "setup_criteria_list": [],
    state: "",
  };

  const navigate = useNavigate();

  const methods = useForm<PadFormProps>({
    mode: 'onChange',
    defaultValues: {
      editing: padToEdit === null,
      pad: padToEdit || defaultPadValues,
      customMarginError: null,
    }
  });
  const { control, reset, getValues, handleSubmit, setValue,  watch, trigger, formState: { errors } } = methods;

  useEffect(() => {
    if (padToEdit) {
      reset({
        pad: padToEdit
      });
    }
  }, [padToEdit, reset]);

  useEffect(() => {
    if (padToEdit) {
      savePrevPadOnLocalStorage(padToEdit);
    }
  }, []);

  const getPrevPadKey = (padId:string | number): string => {
    return "prevPad" + padId;
  }

  const savePrevPadOnLocalStorage =  (pad) => {
    const prevPadJSON = JSON.stringify(pad);
    const prevPadKey = getPrevPadKey(pad.pad_id);
    localStorage.setItem(prevPadKey, prevPadJSON);
  }

  const onSubmit = async data => {
    setHideSubmitButton(true);
    let prevPad:Pad;
    const curPad = data.pad;
    let payload: PadPayload = {curPad,email: ""};

    if (padToEdit){
      const prevPadKey = getPrevPadKey(padToEdit.pad_id);
      const prevPadJSON = localStorage.getItem(prevPadKey)
      prevPad = JSON.parse(prevPadJSON);
    }

    if (process.env.NODE_ENV === "development") {
      if (prevPad) {
        prevPad.created_by = "239363"; // local-dev user id for qa-db
        payload.prevPad = prevPad;
      }
      curPad.created_by = "239363"
      payload.email = "LocalHost"

    } else {
      if (prevPad) {
        payload.prevPad = prevPad;
        payload.prevPad.created_by = useUserSession().user.id;
      }
      payload.curPad.created_by = useUserSession().user.id;
      payload.email = useUserSession().user.email;
    }

    try {
      let response;
      if (!!padToEdit) {
        response = await fetch(`/api/pads/updatePAD`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(payload),
        });
      } else {
        response = await fetch(`/api/pads/savePAD`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(payload),
        });
      }

      if (response.ok) {
        const responseData = await response.json();

        showNotification("success", "Pad saved successfully");
        await props.store.invalidateQuery(refreshKey);

        if (!padToEdit && responseData) {
          const newPadId = responseData;

          setValue("pad.pad_id", newPadId);
          const newPad = {...data.pad, pad_id: newPadId};
          savePrevPadOnLocalStorage(newPad);
          navigate(`/pads/edit/${newPadId}`, { state: { pad: newPad } });
        } else {
          setTimeout(() => {
            if (!curPad.active) {
              navigate(`/pads`);
            }
          }, 1000);
        }
      } else {
        const errorData = await response.json();
        console.error("Error saving pad:", errorData);
        showNotification("error", "Failed to save pad");
      }
    } catch (error) {
      console.error("Error saving pad:", error);
      showNotification("error", "Failed to save pad");
    }
    finally {
      setHideSubmitButton(false);
    }
  };


  const marketplaceType = watch("pad.marketplace_type");
  const dsp = watch("pad.dsp");
  const setupCriteria = watch("pad.setup_criteria");
  const setupCriteriaList = watch("pad.setup_criteria_list");

  return (
    <Paper elevation={4} style={{ padding: 16, backgroundColor: "rgba(250, 250, 250, 0.9)", margin: 96 }}>
      <Typography variant="h5">{padToEdit && padToEdit.pad_id ? "Edit pad" : "Create a new pad"}</Typography>
      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={10}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PadSetup control={control} setValue={setValue} getValues={getValues} marketplaceType={marketplaceType} isActive={isActive} dsp={dsp} isEditing={!!padToEdit} setupCriteria={setupCriteria} setupCriteriaList={setupCriteriaList} />
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column", margin: 48 }}>
                <Button data-testid={"pad-form-submit-button"} type="submit" variant="contained" color="primary" style={{ padding: "24px" }} disabled={!isActive || hideSubmitButton}>
                  Submit
                </Button>
              </div>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Paper>
  );
};
