import { Link as RouterLink, useLocation } from "react-router-dom";
import { AppBar, Breadcrumbs, IconButton, Link, Toolbar, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export interface CustomAppBarProps {
  handleBreadcrumbClick?: (index: number) => void
  pathNames: string[]
}

const shouldDisableEditLink = (name: string): boolean => {
  return (name === "edit" || /^\d+$/.test(name))
};


export default function BreadCrumbsAppBar(props: CustomAppBarProps) {
  const { handleBreadcrumbClick, pathNames } = props
  const location = useLocation()

  return (
    <AppBar data-testId="application-bar" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }}>
      <Toolbar>
        <IconButton size="large" edge="start" color="black" aria-label="menu">
          <AppsIcon />
        </IconButton>
        <Typography variant="h7" noWrap component="div" color="black">
          <Breadcrumbs separator={<KeyboardDoubleArrowRightIcon style={{ color: "lightgray" }} />} aria-label="breadcrumb">
            <Link component={RouterLink} to="/" color="inherit" sx={{ textDecoration: 'none' }} onClick={e => {
              e.preventDefault()
            }}>
              Demand
            </Link>
            {pathNames.map((name, index) => {
              const decodedName = decodeURIComponent(name)
              const encodedName = encodeURIComponent(name)
              const isEditOrId = shouldDisableEditLink(name)
              return isEditOrId ? (
                <Typography key={name} color="textSecondary">
                  {decodedName}
                </Typography>
              ) : (
                <Link
                  key={name}
                  component={RouterLink}
                  to={location.pathname.endsWith(`/${name}`) ? location.pathname :`/${encodedName}`}
                  color="inherit"
                  onClick={() => handleBreadcrumbClick?.(index)}
                  sx={{ textDecoration: 'none' }}
                >
                  {decodedName}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
